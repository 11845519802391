
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CiTable, { ITableData, ICell } from '@/modules/common/components/ci-table';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import type Day from '@/modules/common/types/day.type';
import CompareFiltersBar from './compare-filters-bar.vue';
import PromotionsService, { PromotionsServiceS } from '../../promotions.service';
import { COMPARE_KEY, COMPARE_VALUES } from '../../consts/compare-filter-values';
import PromotionsDocumentModel from '../../models/promotions-document.model';

@Component({
    components: { CompareFiltersBar, CiTable },
})
export default class PromotionsAnalysisTable extends Vue {
    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    public tableConfig = {};

    mounted() {
        this.tableConfig = {
            width: '100%',
            cellSize: [
                {
                    width: [['90px'], ['120px', '120px', '120px', '120px']],
                    height: ['50px'],
                },
                {
                    width: [['120px', '120px']],
                    height: ['50px'],
                },
            ],
        };

        this.scrollToActiveRow();
    }

    get isLoading() {
        const state = this.promotionsService.isLoading || this.promotionsService.isComparedLoading;

        if (!state) {
            this.scrollToActiveRow();
        }

        return state;
    }

    dayToDate(day: Day): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date(year, month, day);
        const dayName = date
            .toLocaleDateString('en-US', { weekday: 'short' })
            .toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    get tableData(): ITableData {
        const { currentHotelId } = this.userService;
        const { competitors } = this.documentFiltersService;
        const { days } = this.documentFiltersService;
        const { comparisonFilter } = this.promotionsService;

        const {
            data: mainDoc, comparedData: compDoc, provider, programView,
        } = this.promotionsService;

        const titleStyle = {
            width: '100%',
        };

        const subTitleStyle = {
            width: '100%',
        };

        const cellStyle = {
            display: 'block',
            width: '100%',
            'max-width': '110px',
            fontWeight: 'inherit',
        };

        const greenCheckmark: ICell = {
            icon: 'icon-v-mark',
            style: { ...cellStyle, color: '#01B875' },
        };

        const greyCross: ICell = {
            icon: 'icon-x-mark',
            style: cellStyle,
        };

        const na: ICell = {
            value: 'N/A',
            style: cellStyle,
        };

        const percentCell = (value: number): ICell => ({
            value: `${value}%`,
            style: cellStyle,
        });

        const getProgramData = (doc: PromotionsDocumentModel | null) => (
            doc && provider
                ? [...competitors, currentHotelId as number].reduce((acc, hotelId) => {
                    acc[hotelId] = days.map(day => {
                        const programs = this.promotionsService
                            .getPrograms(day, provider, hotelId, doc);

                        const program = programs ? programs[programView] : null;

                        if (!program) {
                            return na;
                        }

                        if (!program.status) {
                            return greyCross;
                        }

                        return program.percentage
                            ? percentCell(program.percentage)
                            : greenCheckmark;
                    });

                    return acc;
                }, {} as { [key: number]: any[] })
                : null
        );

        const mainProgData = getProgramData(mainDoc);
        const compProgData = getProgramData(compDoc);
        const dumbData = days.map(_ => ' ');

        const mainTitle = comparisonFilter.key === COMPARE_KEY.DIFF_DAYS ? 'Today' : '';
        const compareValue = COMPARE_VALUES[comparisonFilter.key].find(item => item.value === comparisonFilter.value);
        const compareTitle = compareValue ? compareValue.name : '';

        if (!currentHotelId) {
            return [];
        }

        return [{
            isSticky: true,
            boldRow: this.documentFiltersService.todayDate,
            columns: [
                {
                    title: 'Date',
                    titleStyle,
                    data: [{
                        title: '_',
                        titleStyle: { opacity: '0' },
                        data: days.map(day => this.dayToDate(day)),
                    }],
                }, {
                    title: this.hotelsService.getHotelName(currentHotelId),
                    titleStyle: { ...titleStyle, color: '#00759e' },
                    data: [
                        {
                            title: mainTitle,
                            titleStyle: subTitleStyle,
                            data: !this.isLoading ? mainProgData![currentHotelId] : dumbData,
                        },
                        {
                            title: compareTitle,
                            titleStyle: subTitleStyle,
                            data: !this.isLoading ? compProgData![currentHotelId] : dumbData,
                        },
                    ],
                },
            ],
        }, {
            boldRow: this.documentFiltersService.todayDate,
            columns: competitors.map(competitorId => ({
                title: this.hotelsService.getHotelName(competitorId),
                titleStyle,
                data: [
                    {
                        title: mainTitle,
                        titleStyle: subTitleStyle,
                        data: !this.isLoading ? mainProgData![competitorId] || [] : dumbData,
                    },
                    {
                        title: compareTitle,
                        titleStyle: subTitleStyle,
                        data: !this.isLoading ? compProgData![competitorId] || [] : dumbData,
                    },
                ],
                isFolding: true,
            })),
        }];
    }

    scrollToActiveRow() {
        const { table } = this.$refs as { table: CiTable };
        const { todayDate } = this.documentFiltersService;

        if (table && todayDate) {
            table.scrollToRow(todayDate);
        }
    }
}
