var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"promotions-table",on:{"mousedown":_vm.startDragScroll}},[(!_vm.isNoData)?_c('div',{staticClass:"promotions-table__data-container"},[_c('div',{class:[
                'promotions-table__row',
                'promotions-table__row--header',
                _vm.isLoading && 'skeleton-container skeleton-container--gray',
            ]},[_c('div',{staticClass:"promotions-table__sticky-column"},[_c('div',{staticClass:"promotions-table__cell promotions-table__cell--date"},[_c('span',{class:{ skeleton: _vm.isLoading  }},[_vm._v(" Date ")])]),_c('div',{class:[
                    'promotions-table__cell',
                    'promotions-table__cell--main',
                    'promotions-table__cell--hotel-label',
                ]},[_c('div',[_c('span',{class:{ skeleton: _vm.isLoading  },attrs:{"title":_vm.mainHotelLabel}},[_vm._v(" "+_vm._s(_vm._f("ClipText")(_vm.mainHotelLabel))+" ")])]),_vm._m(0)])]),_vm._l((_vm.hotelList),function(hotelName,index){return _c('div',{key:hotelName + index,staticClass:"promotions-table__cell promotions-table__cell--hotel-label"},[_c('div',[_c('span',{class:{ skeleton: _vm.isLoading  },attrs:{"title":hotelName}},[_vm._v(" "+_vm._s(_vm._f("ClipText")(hotelName))+" ")])]),_vm._m(1,true)])})],2),_vm._l((_vm.tableData),function(rowData,index){return _c('div',{key:'row' + index,class:{
                'promotions-table__row': true,
                'skeleton-container skeleton-container--gray': _vm.isLoading,
            },on:{"click":function($event){return _vm.openPopup(index + 1)}}},[_c('div',{staticClass:"promotions-table__sticky-column"},[_c('div',{class:[
                        'promotions-table__cell',
                        'promotions-table__cell--date',
                    ]},[_c('span',{class:{ skeleton: _vm.isLoading },domProps:{"textContent":_vm._s(_vm.mainData[index] ? _vm.mainData[index].date : '99')}})]),_c('div',{staticClass:"promotions-table__cell"},[_c('div',[_c('span',{class:{
                                skeleton: _vm.isLoading,
                                ['promotions-table__status--' + (_vm.mainData[index] && _vm.mainData[index].programStatus)]: true,
                            },domProps:{"textContent":_vm._s(_vm.mainData[index] ? _vm.mainData[index].programsCount : '99')}})]),_c('div',[_c('span',{class:{
                                skeleton: _vm.isLoading,
                                ['promotions-table__status--' + (_vm.mainData[index] && _vm.mainData[index].dealsStatus)]: true,
                            },domProps:{"textContent":_vm._s(_vm.mainData[index] ? _vm.mainData[index].dealsCount : '99')}})])])]),_vm._l((rowData),function(hotelData,index){return _c('div',{key:hotelData.day + '-' + hotelData.hotelId + index,staticClass:"promotions-table__cell promotions-table__cell--value"},[_c('div',[_c('span',{class:{
                            skeleton: _vm.isLoading,
                            ['promotions-table__status--' + (hotelData.programStatus)]: true,
                        },domProps:{"textContent":_vm._s(_vm.isLoading ? 99 : hotelData.programsCount)}})]),_c('div',[_c('span',{class:{
                            skeleton: _vm.isLoading,
                            ['promotions-table__status--' + (hotelData.programStatus)]: true,
                        },domProps:{"textContent":_vm._s(_vm.isLoading ? 99 : hotelData.dealsCount)}})])])})],2)})],2):_c('div',[_vm._v(" There is no data. Try change the filters. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"promotions-table__subheader"},[_c('div',[_vm._v(" Programs ")]),_c('div',[_vm._v(" Deals ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"promotions-table__subheader"},[_c('div',[_vm._v(" Programs ")]),_c('div',[_vm._v(" Deals ")])])
}]

export { render, staticRenderFns }